<template>
	<div id="app">
		<keep-alive :include="routes"><router-view :key="key" /></keep-alive>
	</div>
</template>
<script>
export default {
	data() {
		return {
			routes: ['fbwHome','yqlHome','searchCompany']
		};
	},
	computed: {
		key() {
			return this.$route.name;
		}
	}
};
</script>
<style scoped>
body {
	margin: 0;
}
#app {
	background-color: #f5f5f5;
}
</style>
