import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/orderPay',
		name: 'orderDetail',
		component: () => import( /* webpackChunkName: "orderDetail" */ '../views/order/orderDetail')
	},
	{
		path: '/banner/qmdlr',
		name: 'qmdlr',
		component: () => import( /* webpackChunkName: "banner" */ '../views/banner/qmdlr'),
		meta: {
			title: '全民代理人'
		}
	},
	{
		path: '/banner/success',
		name: 'qmdlr',
		component: () => import( /* webpackChunkName: "banner" */ '../views/banner/success'),
		meta: {
			title: '成功率评估'
		}
	},
	{
		path: '/banner/xbsx',
		name: 'xbsx',
		component: () => import( /* webpackChunkName: "banner" */ '../views/banner/xbsx'),
		meta: {
			title: '新版上线'
		}
	},
	{
		path: '/queryCategory',
		name: 'queryCategory',
		component: () => import( /* webpackChunkName: "queryCategory" */ '../views/queryCategory'),
		meta: {
			title: '查询同行'
		}
	},
	{
		path: '/recommendCategory',
		name: 'recommendCategory',
		component: () => import( /* webpackChunkName: "queryCategory" */ '../views/queryCategory'),
		meta: {
			title: '类别推荐'
		}
	},
	{
		path: '/myCategory',
		name: 'myCategory',
		component: () => import( /* webpackChunkName: "category" */ '../views/category/category'),
		meta: {
			title: '类别推荐'
		}
	},
	{
		path: '/banner/share',
		name: 'share',
		component: () => import( /* webpackChunkName: "share" */ '../views/banner/share'),
		meta: {
			title: '邀请注册'
		}
	},
	{
		path: '/shareA',
		name: 'cityAgentShare',
		component: () => import( /* webpackChunkName: "shcityAgentShare" */ '../views/banner/cityAgentShare'),
		meta: {
			title: '城市代理人'
		}
	},
	{
		path: '/xcxBanner',
		name: 'xcxBanner',
		component: () => import( /* webpackChunkName: "xcxBanner" */ '../views/banner/xcxbanner'),
		meta: {
			title: '知依昇'
		}
	},
	{
		path: '/searchCompany',
		name: 'searchCompany',
		component: () => import( /* webpackChunkName: "assets" */ '../views/service/searchCompany'),
		meta: {
			title: '搜索'
		}
	},
	{
		path:'/fbw',
		name: 'fbwHome',
		meta:{
			origin:'FBW'
		},
		component: () => import('../views/fbw/home')
	},
	{
		path:'/fbw/search',
		name: 'fbwSearch',
		meta:{
			origin:'FBW'
		},
		component: () => import(/* webpackChunkName: "fbwSearch" */ '../views/fbw/search')
	},
	{
		path:'/fbw/recommend',
		name: 'fbwRecommend',
		meta:{
			origin:'FBW'
		},
		component: () => import(/* webpackChunkName: "fbwRecommend" */ '../views/fbw/recommend')
	},
	{
		path: '/assets',
		name: 'assets',

		component: () => import( /* webpackChunkName: "assets" */ '../views/service/assets'),
		meta: {
			origin:'FBW',
			title: '知产管理'
		}
	},
	{
		path: '/trademarkList',
		name: 'trademarkList',
		component: () => import( /* webpackChunkName: "assets" */ '../views/service/trademarkList'),
		meta: {
			title: '商标信息',
			origin:'FBW',
		}
	},
	{
		path: '/tortToList',
		name: 'tortToList',
		component: () => import( /* webpackChunkName: "assets" */ '../views/service/tortToList'),
		meta: {
			title: '详情',
			origin:'FBW',
		}
	},
	{
		path:'/yql',
		name: 'yqlHome',
		meta: {
			origin:'YQL',
		},
		component: () => import('../views/yql/home')
	},
	{
		path:'/yql/search',
		name: 'yqlSearch',
		meta: {
			origin:'YQL',
		},
		component: () => import(/* webpackChunkName: "yqlSearch" */ '../views/yql/search')
	},
	{
		path:'/yql/recommend',
		name: 'yqlRecommend',
		meta: {
			origin:'YQL',
		},
		component: () => import(/* webpackChunkName: "yqlRecommend" */ '../views/yql/recommend')
	},
	{
		path:'/yz',
		name: 'yzHome',
		meta: {
			origin:'yz',
		},
		component: () => import('../views/yz/home')
	},
	{
		path:'/yz-pc',
		name: 'yzPcHome',
		meta: {
			origin:'yz',
		},
		component: () => import('../views/yz-pc/index')
	},
	{
		path:'/fbw2',
		name: 'fbw2',
		meta: {
			origin:'fbw',
		},
		component: () => import('../views/fbw/fbw2/home')
	},
	{
		path:'/fbw2/search',
		name: 'fbw2Search',
		meta: {
			origin:'fbw2',
		},
		component: () => import(/* webpackChunkName: "yqlSearch" */ '../views/fbw/fbw2/search')
	},
	{
		path:'/fbw2/recommend',
		name: 'fbw2Recommend',
		meta: {
			origin:'fbw2',
		},
		component: () => import(/* webpackChunkName: "yqlRecommend" */ '../views/fbw/fbw2/recommend')
	},
]

const router = new VueRouter({
	mode:'history',
	routes
})

export default router
