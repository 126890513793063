import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/utils/rem"
import '@/router/interceptor' //路由拦截器
Vue.config.productionTip = false
import 'vant/lib/index.css';
import {
	Empty,
	Loading,
	Picker,
	Popup
} from 'vant';

Vue.use(Loading);
Vue.use(Empty);
Vue.use(Picker);
Vue.use(Popup);
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
